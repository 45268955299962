import React, { createContext, useReducer } from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import { Market } from './components/Market';
import { Exchange } from './components/Exchange';
import { Redeem } from './components/Redeem';
import { ListRedemption } from './components/ListRedemption';
import { Navbar } from './components/Navbar';
import { reducer, initialState } from "./reducer/useReducer";

// context-api
export const UserContext = createContext();

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <>
      <UserContext.Provider value={{ state, dispatch }}>
        <Navbar />
        <Switch>
          {/*<Route path="/" component={AboutUs} exact />*/}
          <Route path="/" component={Market} exact />
          <Route path="/exchange" component={Exchange} exact />
          <Route path="/redeem" component={Redeem} exact />
          <Route path="/list" component={ListRedemption} exact />
        </Switch>
      </UserContext.Provider>
    </>
  );
}

export default App;
