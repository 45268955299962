import {
  Typography,
  Card,
  CardContent,
} from '@mui/material';

import {
  CCBox, CCSubBox,
} from './Shared'

import {
  listCert,
  toShortAddr,
  integerWithCommas,
  GCX_TO_CO2_RATIO,
} from '../functions/functions'

import { useEffect, useState } from 'react';

export const ListRedemption = (props) => {
  const [certs, setCerts] = useState(null);
  useEffect(() => {
    async function fetchCerts() {
      const certs = await listCert();
      setCerts(certs);
    };
    fetchCerts();
  }, []);

  function formatTonnes(v) {
    // `v` could be a floating point number.
    let [integerPart, decimalPart] = v.toFixed(10).split(".");
    const withComma = integerWithCommas(integerPart)
    if (decimalPart) {
      // remove all trailing 0
      decimalPart = decimalPart.replace(/0*$/, "");
    }

    return decimalPart ? [withComma, decimalPart].join(".") : withComma;
  }

  const description = "Here is list showing past record of redemption.";

  return (
    <CCBox>
      <CCSubBox
        title="Certificate Issued via Blockchain" description={description}
        sx={{
          backgroundColor: "transparent",
          ".cert:nth-child(even)": {
            background: "#e5ffe5",
          },
          }}
        >
        {certs && certs.map((cert, i) => {
          return (
            <Card key={i} sx={{mb:"20px", display:"flex"}} className="cert">
              <CardContent sx={{width: "50%"}}>
                <Typography variant="h2" sx={{fontSize: "18px", fontWeight: "bold"}}>{cert.name}</Typography>
                <Typography variant="body2" sx={{fontSize: "14px"}}>{cert.time && cert.time.toLocaleString()}</Typography>
                <Typography variant="subtitle1" sx={{fontSize: "8px"}}>{toShortAddr(cert.address)}</Typography>
                <Typography variant="subtitle1" sx={{fontSize: "8px"}}>Ref: {cert.id}</Typography>
              </CardContent>
              <CardContent sx={{width: "50%"}}>
                <Typography variant="body2" textAlign="right">{formatTonnes(cert.amount * GCX_TO_CO2_RATIO)} metric tonnes</Typography>
              </CardContent>
            </Card>
          )
        })}
      </CCSubBox>
    </CCBox>
  )
}