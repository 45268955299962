import Web3 from 'web3'
import tokenABI from '../abi/CarbonCoin.json'
import tokenProxyABI from '../abi/CarbonCoinProxy.json'
import tokenUsdtABI from '../abi/USDT.json'
import WalletConnectProvider from "@walletconnect/web3-provider";
import md5 from 'md5'

const usdcAddressMainnet = '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'
const usdcAddressRinkeby = '0xeb8f08a975Ab53E34D8a0330E0D34de942C95926'
const contractAddress = process.env.REACT_APP_CONTRACTADDRESS
const contractProxyAddress = process.env.REACT_APP_CONTRACTPROXYADDRESS

export const GCX_TO_CO2_RATIO = 10;  // we are talking about 1 GCX tokent to how many metric tonnes of CO2.

export const toWei = ((amount, ccy) => {
    if (ccy === 'ETH')
        return Web3.utils.toWei(amount, 'ether')
    else if (ccy === 'USDT')
        return Web3.utils.toWei(amount, 'mwei')

    return "toWei(amount, 'ether')"
})

export const fromWei = ((amount, ccy) => {
    if (ccy === 'ETH')
        return Web3.utils.fromWei(amount, 'ether')
    else if (ccy === 'USDT')
        return Web3.utils.fromWei(amount, 'mwei')

    return "toWei(amount, 'ether')"
})

export const toShortAddr = (addr) => {
    if (!addr) {
        return '';
    }
    return addr.substring(0, 2 + 4) + '...' + addr.substring(addr.length - 4);
}

export const isAuth = ((history) => {
    return new Promise(async (resolve, reject) => {
        const Auth = localStorage.getItem("AuthToken");
        if (Auth === "" || Auth === null) {
            history.push('/');
            return;
        }
        else {
            const options = {
                method: "POST",
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-auth-token': Auth,
                }
            };

            // Making Request Here
            fetch(process.env.REACT_APP_BASE_URL + "/core/account/getUser", options)
                .then(response => response.json()).then(data => {
                    if (data.results && data.results[0] && data.results[0].firstName && data.results[0].lastName) resolve(data.results[0])
                    if (data.code === 0) {

                    } else {
                        window.localStorage.removeItem("AuthToken");
                        history.push('/');
                    }
                    reject()
                }).catch((e) => {
                    history.push('/');
                })
        }
    })
})

export const web3Connect = async () => {
    return new Promise(async (resolve) => {
        //console.log('trying to use buildin ext')
        const web3 = new Web3(Web3.givenProvider)
        await web3.eth.requestAccounts()
            .then(async (connected) => {
                //console.log(connected)
                if (connected) {
                    resolve(web3)
                }
            }).catch(async () => {
                //console.log('trying to use Wallet Connect')
                const provider = new WalletConnectProvider({
                    infuraId: "9aa3d95b3bc440fa88ea12eaa4456161",
                });

                //  Enable session (triggers QR Code modal)
                await provider.enable()
                //console.log('WalletConnect enabled')
                const web3WalletConnect = new Web3(provider);

                resolve(web3WalletConnect)
            })
        const chainId = await web3.eth.getChainId()
        if (chainId !== 1 && chainId !== 4)
            await web3.currentProvider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: Web3.utils.toHex(1) }],
            });
    })
}

export const connectWallet = () => {
    return new Promise(async (resolve) => {
        let web3 = await web3Connect()
        web3.eth.requestAccounts()
            .then(async (connected) => {
                //console.log(connected)
                if (connected) {
                    const address = connected[0]
                    window.localStorage.setItem("web3", web3)
                    resolve({
                        address: address,
                        chainId: await web3.eth.getChainId(),
                        tokenBalance: await getTokenBalance(address) || 0,
                        //usdcBalance: await getUSDCBalance(address) || 0,
                        gcxBalance: await getGCXBalance(address) || 0
                    })
                }
            }).catch(async () => {
                const address = (await web3.eth.getAccounts())[0]
                const chainId = await web3.eth.getChainId();
                resolve({
                    address,
                    chainId,
                    tokenBalance: await getTokenBalance(address),
                    //usdcBalance: await getUSDCBalance(address),
                    gcxBalance: await getGCXBalance(address)
                })
            })
    })
}

export const getExchangeableTokenSymbol = (() => {
    return new Promise(async (resolve) => {
        let web3 = await web3Connect()
        const gcxProxy = new web3.eth.Contract(tokenProxyABI, contractProxyAddress, {});
        const exchangeableTokenAddress = await gcxProxy.methods.exchangeableToken().call()
        const exchangeableToken = new web3.eth.Contract(tokenProxyABI, exchangeableTokenAddress, {});
        resolve(await exchangeableToken.methods.symbol().call())
    })
})

export const exchange = async (walletAddress, usdt, gcx) => {
    //usdt = Math.trunc(usdt * 1000000) / 1000000
    let web3 = await web3Connect()
    const gcxProxy = new web3.eth.Contract(tokenProxyABI, contractProxyAddress, {});
    const exchangeableToken = new web3.eth.Contract(tokenUsdtABI, await gcxProxy.methods.exchangeableToken().call(), {});

    //const feePrecent = await gcxProxy.methods.getExchangeFee().call()
    //const fee = Math.trunc(usdt / feePrecent * 1000000) / 1000000

    //const usdtInWei = web3.utils.toWei((usdt).toFixed(6), 'mwei')
    const usdtWithFeeInWei = web3.utils.toWei((usdt).toFixed(6), 'mwei')
    const gcxInWei = web3.utils.toWei((gcx).toFixed(6), 'mwei')

    const allowance = await exchangeableToken.methods.allowance(walletAddress, contractProxyAddress).call();

    const calculatedApproveValue = await exchangeableToken.methods.MAX_UINT().call()

    let approveTxn = true
    if (!((allowance) >= calculatedApproveValue)) {
        approveTxn = false
        approveTxn = await exchangeableToken.methods.approve(contractProxyAddress, calculatedApproveValue).send({ from: walletAddress });
    }
    if (approveTxn) {
        await gcxProxy.methods.exchangeToken(gcxInWei).send({ from: walletAddress });
    }
}

export const redeem = async ({ walletAddress, fullName, email, gcxAmount }) => {
    let web3 = await web3Connect()
    const gcxProxy = new web3.eth.Contract(tokenProxyABI, contractProxyAddress, {});

    const redeemTxn = await gcxProxy.methods.redeem(
        web3.utils.toHex(fullName).substring(0, 256 * 2),
        web3.utils.toWei(gcxAmount.toString(), "mwei"),
        web3.utils.toHex(email).substring(0, 256 * 2)
    )
        .send({
            from: walletAddress,
            value: await gcxProxy.methods.getRedeemFee().call()
        })
        .then((result) => {
            return result
        }).catch((err) => {
            console.error("Error in redeem", err);
            throw err;
        });
    return redeemTxn
    // listCert()
}

const _parseCert = (async (raw, web3) => {
    if (raw === null) {
        return {
            name: "unknown",
            address: "",
            time: null,
            amount: ""
        }
    }

    return {
        name: web3.utils.hexToUtf8(web3.utils.numberToHex(raw[0])),
        address: raw[1],
        time: new Date(parseInt(raw[2] + '000')),
        amount: web3.utils.fromWei(raw[3], "mwei"),
        id: md5(raw[0] + raw[1] + raw[2] + raw[3] + raw[4])
    }
})

export const listCert = async () => {
    connectWallet()
    const certs = [];
    let web3 = await web3Connect()
    const gcxProxy = new web3.eth.Contract(tokenProxyABI, contractProxyAddress, {});

    const certIndex = await gcxProxy.methods.getCertIndex().call();
    const nCerts = certIndex;
    for (let i = 0; i < nCerts; i++) {
        let rawCert = null;
        try {
            rawCert = await gcxProxy.methods.listCert(i).call()
        }
        catch (err) {
            console.error("failed to get Cert at %d", i, err);
        }
        const cert = await _parseCert(rawCert, web3);
        certs.push(cert);
    }
    return certs;
}

export const getChainName = (chainId) => {
    try {
        switch (chainId) {
            case 1:
                return "mainnet";
            case 4:
                return "rinkeby";
            default:
                return "unknown";
        }
    } catch {
        return "unknown";
    }
}

export const getGCXBalance = async (walletAddress) => {
    let web3 = await web3Connect()
    const gcxToken = new web3.eth.Contract(tokenABI, contractAddress, {});
    const gcxBalance = await gcxToken.methods.balanceOf(walletAddress).call((err, balance) => {
        if (err) {
            console.error("error when getGCXBalance", err);
            return err;
        }
        return balance;
    })
    return web3.utils.fromWei(gcxBalance, "mwei");
}

export const getTokenBalance = (async (walletAddress) => {
    let web3 = await web3Connect()
    const gcxProxy = new web3.eth.Contract(tokenProxyABI, contractProxyAddress, {});
    const tokenAddress = await gcxProxy.methods.exchangeableToken().call()
    console.log(tokenAddress)
    const exchangeableToken = new web3.eth.Contract(tokenABI, tokenAddress, {});
    const usdt = await exchangeableToken.methods.balanceOf(walletAddress).call((err, balance) => {
        if (err) {
            console.error("error when getTokenBalance", err);
            return err;
        }
        return balance;
    })
    const usdtBalance = web3.utils.fromWei(usdt, 'mwei')
    return usdtBalance;
})

export const getUSDCBalance = (async (walletAddress) => {
    let web3 = await web3Connect()
    const chainId = await web3.eth.getChainId()
    let usdcToken
    if (chainId === 1)
        usdcToken = new web3.eth.Contract(tokenABI, usdcAddressMainnet, {});
    else if (chainId === 4)
        usdcToken = new web3.eth.Contract(tokenABI, usdcAddressRinkeby, {});
    const usdc = await usdcToken.methods.balanceOf(walletAddress).call((err, balance) => {
        if (err) {
            console.error("error when getUSDCBalance", err);
            return err;
        }
        return balance;
    })
    const usdcBalance = web3.utils.fromWei(usdc, 'mwei')
    return usdcBalance;
})

export const getExchangeRate = (async () => {
    connectWallet()
    let web3 = await web3Connect()
    const gcxProxy = new web3.eth.Contract(tokenProxyABI, contractProxyAddress, {});
    return await gcxProxy.methods.getExchangeRate().call((err, ret) => {
        if (err) {
            console.log("An error occured", err)
            return
        }
        return ret
    })
})

export const getExchangeFee = (async () => {
    connectWallet()
    let web3 = await web3Connect()
    const gcxProxy = new web3.eth.Contract(tokenProxyABI, contractProxyAddress, {});
    return await gcxProxy.methods.getExchangeFee().call((err, ret) => {
        if (err) {
            console.log("An error occured", err)
            return
        }
        return ret
    })
})

export function toFixed(x) {
    let e;
    if (Math.abs(x) < 1.0) {
        e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}

/*
From https://stackoverflow.com/a/2901298/1822624.
***** IMPORTANT *****
It does not work with numbers with decimal point.
*/
export function integerWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export async function importGcxToken() {
    try {
        connectWallet()
        let web3 = await web3Connect()
        web3.currentProvider.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: contractAddress,
                    symbol: 'GCX',
                    decimals: '6',
                    image: 'https://app.gctc.io/assets/token-icon.png',
                },
            },
        });
    } catch (error) {
        // handle errors
    }
}