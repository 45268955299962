import { useState, useContext, useEffect } from "react"
import { useHistory } from "react-router-dom";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { ReactComponent as ArrowDownwardIcon } from '../icons/down_arrow.svg';

import {
  CCBox,
  CCSubBox,
  CCSubmitButton,
  CryptoCurrencyPicker
} from './Shared'

import {
  exchange,
  getExchangeRate,
  getExchangeFee
} from '../functions/functions'

import { UserContext } from '../App';


export const Exchange = (props) => {
  const fetchRate = (() => {
    getExchangeRate().then((rate) => {
      // This exchange rate means 1 USDT to N uGCX.
      // note: USDT is interchangable with USDC.
      // note: 1 GCX = 1,000,000 uGCX
      //console.log(`Exchange rate is set to ${rate}`);
      setExchangeRate(rate)
    })
  })

  const { state } = useContext(UserContext);
  useEffect(() => {
    fetchRate()
  }, []);
  let history = useHistory();

  const sources = [
    { code: "usdt", fullName: "USDT", balance: state.tokenBalance },
    // { code: "usdc", fullName: "USDC", balance: state.usdcBalance },
    // {code: "eth", fullName: "ETH"},
  ];

  const destinations = [
    { code: "gcx", fullName: "GCX", balance: state.gcxBalance },
  ]

  const [srcCryptoCode, setSrcCryptoCode] = useState(sources[0].code);
  //const [dstCryptoCode, setDstCryptoCode] = useState(destinations[0].code);
  const dstCryptoCode = destinations[0].code
  const [srcCryptoValue, setSrcCryptoValue] = useState("0");
  const [dstCryptoValue, setDstCryptoValue] = useState("0");
  const [srcCryptoMessage, setSrcCryptoMessage] = useState("");
  const [dstCryptoMessage, setDstCryptoMessage] = useState("");
  const [exchangeRate, setExchangeRate] = useState(0);
  const [exchangeFee, setExchangeFee] = useState(0);

  const [showLoading, setShowLoading] = useState(false);
  const [exchangeInProgress, setExchangeInProgerss] = useState(false);
  const [dialog, setDialog] = useState({
    title: "Redeem",
    message: "",
    actions: ["close"],
  });

  function isValidUsdtAmount(s) {
    //const re = /^((\d+)|(\d\.\d\d{0,6}))$/;
    //return re.test(s);
    return true
  }

  const handleSrcCryptoValueChange = async (e) => {
    const raw = e.target.value.trim();
    setSrcCryptoValue(raw);
    setSrcCryptoMessage("");
    //const integerRegex = /^\d+$/;
    if (raw === "") {
      // empty string. nothing we need to do.
      return;
    }

    if (!isValidUsdtAmount(raw)) {
      setSrcCryptoMessage("Your input does not look valid. It must be either an integer (e.g. '123' or a number with at most 4 decimal points (e.g. '0.1234'.)");
      return;
    }

    let numeric = parseFloat(raw);
    if (Number.isNaN(numeric)) {
      setSrcCryptoMessage("Your input does not look valid. It must be either an integer (e.g. '123' or a number with at most 4 decimal points (e.g. '0.1234'.)");
      return;
    }

    // setDstCryptoValue(toFixed(numeric * exchangeRate / 1_000_000));
    const gcx = numeric * 1_000_000 / exchangeRate;
    setDstCryptoValue(gcx.toFixed(6));
    setDstCryptoMessage("");

    setExchangeFee((numeric / await getExchangeFee()).toFixed(6))
  }

  const handleDstCryptoValueChange = async (e) => {
    setDstCryptoMessage("");
    let val = e.target.value.trim();
    // TODO: make sure val is a floating point number with at most 6 d.p.
    setDstCryptoValue(val);
    if (!val) {
      // empty. nothing else to do.
      return;
    }

    if (!isValidGcxAmount(val)) {
      setDstCryptoMessage("Your input does not look valid. It must be either an integer (e.g. '123' or a number with at most 6 decimal points (e.g. '0.123456'.)");
      return;
    }

    let numeric = parseFloat(val);
    if (isNaN(numeric)) {
      setDstCryptoMessage("Your input does not look valid. It must be either an integer (e.g. '123' or a number with at most 6 decimal points (e.g. '0.123456'.)");
      return;
    }

    let usd = parseFloat(numeric) * exchangeRate / 1000000
    setSrcCryptoValue(usd.toFixed(6));
    setSrcCryptoMessage("");

    setExchangeFee((usd / await getExchangeFee()).toFixed(6))
  }

  const handleSrcCryptoCodeChange = (e) => {
    setSrcCryptoCode(e.target.value.trim());
  }

  const handleExchange = () => {
    setShowLoading(true);

    if (!srcCryptoValue) {
      return setDialog({
        title: "Error in the form",
        message: "Please specifiy the amount of USDT.",
        actions: ["close"]
      });
    }

    if (!dstCryptoValue) {
      return setDialog({
        title: "Error in the form",
        message: "The GCX amoutn is not set. Please specifiy the amount of USDT and it will update the GCX amount automatically.",
        actions: ["close"]
      });
    }

    if (!state.walletAddress) {
      return setDialog({
        title: "Error in the form",
        message: "wallet is not connected. Please click the 'Connect wallet' button at the top.",
        actions: ["close"]
      });
    }

    //console.log(`Exchange ${srcCryptoCode} ${srcCryptoValue} for ${dstCryptoCode} ${dstCryptoValue}.`)
    //console.log(`The wallet address is ${state.walletAddress}`);

    setDialog({
      title: "Exchange in progress",
      message: <CircularProgress />,
      actions: [],
    })
    setExchangeInProgerss(true);
    exchange(state.walletAddress, parseFloat(srcCryptoValue) + parseFloat(exchangeFee), parseFloat(dstCryptoValue))
      .then(() => {
        setDialog({
          title: "Exchange succeeded!",
          message: "You can now go to the redeem your certificate.",
          actions: ["goRedeem", "close"],
        })
      })
      .catch(err => {
        console.error("exchange failed", err);
        setDialog({
          title: "Exchange failed!",
          message: `The error is "${err.message}".`,
          actions: ["close"],
        })
      })
      .finally(() => {
        setExchangeInProgerss(false);
      });
  }

  const handleClose = () => {
    if (exchangeInProgress) {
      //console.log("exchange in progress. wont close");
      return;
    }

    setShowLoading(false);
  }

  const handleGoRedeem = () => {
    history.push("/redeem");
  }

  function isValidGcxAmount(s) {
    const re = /^((\d+)|(\d\.\d\d{0,5}))$/;
    return re.test(s);
  }

  const description = "Please input the amount of GCX to be swapped.";

  return (
    <CCBox>
      <CCSubBox title="Exchange" description={description}>
        {!state.walletAddress && <Typography>Please connect to your wallet.</Typography>}
        {exchangeRate === 0 && <Typography variant="body2"><CircularProgress />Fetching exchaing rate.</Typography>}
        {state.walletAddress && exchangeRate !== 0 &&
          <>
            <CryptoCurrencyPicker
              items={sources} code={srcCryptoCode} value={srcCryptoValue}
              message={srcCryptoMessage}
              onChangeValue={handleSrcCryptoValueChange}
              onChangeCode={handleSrcCryptoCodeChange}
              selectProps={{
                disabled: true,
                sx: {
                  '&.Mui-disabled': {
                    color: "rgba(0, 0, 0, 1)",
                  },
                  '.MuiSelect-select.Mui-disabled': {
                    "WebkitTextFillColor": "rgba(0, 0, 0, 1)",
                  },
                }
              }}
              inputProps={{
                type: "number",
                step: "1",
                min: 0,
                sx: {
                  // backgroundColor: '#D9D9D9',
                  textAlign: 'right',
                }
              }}
              sx={{
                mb: "40px"
              }}
            />
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <ArrowDownwardIcon />
            </Box>

            <CryptoCurrencyPicker
              items={destinations} code={dstCryptoCode} value={dstCryptoValue}
              message={dstCryptoMessage}
              onChangeValue={handleDstCryptoValueChange}
              // onChangeCode={handleDstCryptoCodeChange}
              selectProps={{
                disabled: true,
                sx: {
                  '&.Mui-disabled': {
                    color: "rgba(0, 0, 0, 1)",
                  },
                  '.MuiSelect-select.Mui-disabled': {
                    "WebkitTextFillColor": "rgba(0, 0, 0, 1)",
                  },
                }
              }}
              inputProps={{
                type: "number",
                step: "1",
                min: 0,
                disabled: false,
                sx: {
                  // backgroundColor: '#D9D9D9',
                  textAlign: 'right',
                }
              }}
              sx={{
                mb: "40px"
              }}
            />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body2">Price:</Typography>
              <Typography variant="body2">{(1_000_000 / exchangeRate).toFixed(6)} USDT/USDC per GCX</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body2">Fee (USDT):</Typography>
              <Typography variant="body2">{exchangeFee}</Typography>
            </Box>
            <CCSubmitButton onClick={handleExchange} fullWidth>Exchange</CCSubmitButton>
          </>
        }
      </CCSubBox>
      <Dialog open={showLoading} onClose={handleClose}>
        <DialogTitle>{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialog.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialog.actions.find(a => a === "close") && <Button onClick={handleClose}>Close</Button>}
          {dialog.actions.find(a => a === "goRedeem") && <Button onClick={handleGoRedeem} color="primary">Redeem now</Button>}
        </DialogActions>
      </Dialog>
    </CCBox>
  )
}