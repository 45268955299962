import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#55CB7D',
      contrastText: '#fff',
    }
  },

  typography: {
    // fontFamily: 'Avenir-Light, "Readex Pro", sans-serif',
    fontFamily: 'Avenir-Light, sans-serif',
    h1: {
      fontStyle: "normal",
      fontSize: "32px",
      fontWeight: 700,
      lineHeight: "40px",
    },
    body1: {
      fontStyle: "normal",
      fontSize: "20px",
      fontWeight: 400,
      lineHeight: "25px",
    }
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "20px",
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "20px",
          "&.Mui-disabled": {
            backgroundColor: "#D9D9D9",
          },
        },
        sizeLarge: {
          height: "61px",
        }
      }
    }
  }
})