const SetInitialState = () => {
  const Auth = localStorage.getItem("AuthToken");
  if (Auth === "" || Auth === null) {
    return {auth: false};
  } else {
    return {auth: true};
  }
};

export const initialState = SetInitialState();
export const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {...state, auth: action.payload};
    case "connectWallet":
      return {...state, walletAddress: action.address}
    case "chainId":
      return {...state, chainId: action.chainId};
    case "setGcxBalance":
      return {...state, gcxBalance: action.amount};
    case "setTokenBalance":
      return {...state, tokenBalance: action.amount}
    case "setUsdcBalance":
      return {...state, usdcBalance: action.amount}
    default:
      return state;
  }
};
