import React from "react";

import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Button,
  OutlinedInput,
  Select, MenuItem,
  Typography,
} from '@mui/material';

import { styled } from '@mui/material/styles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const CCBox = (props) => {
  return (
    <Box
      sx={{
        mt: "112px",
        padding: {
          xs: "12px 16px",
          sm: "28px 36px",
        },
        width: {
          lg: '1024px',
        },
        mx: {
          lg: 'auto',
        },
        ...(props.sx ? props.sx : {})
      }}
    >
      {props.children}
    </Box>
  );
}

const CCTitle = (props) => {
  return (
    <Typography
      variant='h1'
      sx={{
        mb: "15px", color: '#3C9D24', textAlign: 'center',
      }}
    >{props.text}</Typography>
  );
}

const CCDescription = (props) => {
  return (
    <Typography variant='body1' sx={{mb: "24px"}}>{props.text}</Typography>
  )
}

const CCDivider = styled(Divider)((theme) => ({
  margin: "0px -24px 10px -24px",
}))

export const CCSubBox = (props) => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        padding: "20px 24px",
        borderRadius: "16px",
        width: {
          xs: "100%",
          sm: "302px",
          md: "492px",
        },
        mx: {
          xs: "auto",
          sm: "none",
        },
        ...(props.sx ? props.sx : {})
      }}
    >
      {props.title && <CCTitle text={props.title} />}
      {props.description && <CCDescription text={props.description} />}
      {(props.title || props.description) && <CCDivider />}
      {props.children}
    </Box>
  );
}

function getCoinIcon(crypto) {
  switch (crypto.code) {
    case 'usdt':
      return <img src="assets/usdt.png" alt="usdt"/>;
    case 'usdc':
      return <img src="assets/usdc-icon.svg" alt="usdc" style={{width: "28px", height: "28px"}} />;
    case 'gcx':
      return <img src="assets/gcx-icon.png" alt="gcx" style={{width: "29px", height: "32px"}}/>;
    default:
      console.error("Unknown coin Code", crypto.code);
  };
  return null;
}

export const CryptoCurrencyPicker = (props) => {
  const selectedCrypto = props.items.find((crypto) => {
    return crypto.code === props.code;
  });

  const selectDisabled = (props.selectProps && props.selectProps.disabled) || false;

  return (
    <Box sx={{...props.sx}}>
      <FormControl sx={{ m: 0, width: '100%', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }} variant="outlined">
        <Select
          variant="standard"
          disableUnderline={true}
          IconComponent={selectDisabled ? "" : ExpandMoreIcon}
          renderValue={(value) => {
            return (
            <Box sx={{
              'img': {
                mr: "5px",
              },
              display: "flex",
            }}>
              {getCoinIcon(selectedCrypto)}
              <span>{selectedCrypto.fullName}</span>
            </Box>)
          }}
          value={props.code || ''} onChange={props.onChangeCode} {...props.selectProps}>
          {props.items.map((crypto) => {
            return <MenuItem key={crypto.code} value={crypto.code}>{crypto.fullName}</MenuItem>
          })}
        </Select>
        <Typography>Balance: {selectedCrypto.balance}</Typography>

      </FormControl>
      <FormControl sx={{ m: 0, width: '100%', flexDirection: "column", justifyContent: "space-between", alignItems: "center" }} variant="outlined" fullWidth error={!!props.message}>
        <OutlinedInput value={props.value} onChange={props.onChangeValue} fullWidth
          disabled={props.inputProps.disabled || false}
          inputProps={props.inputProps || {}}
          sx={{
            "&.MuiOutlinedInput-root": {
              // adding a shadow from top to bottom.
              boxShadow: "inset 0px 7px 3px -3px rgb(74 74 104 / 10%)",
              borderRadius: "16px",
              overflow: "hidden",
            }
          }}
        />
        <FormHelperText>{props.message}</FormHelperText>
      </FormControl>
    </Box>
  )
}

export const CCSubmitButton = styled(Button)((theme) => ({
  borderRadius: "16px",
  background: '#55CB7D',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  textTransform: 'none',
  color: 'white',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: '30px',
  paddingTop: "15px",
  paddingBottom: "15px",
  marginBottom: "20px",

  boxShadow: "rgb(14 14 44 / 40%) 0px -1px 0px 0px inset",
}));