import React, { useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import { CCBox, CCSubBox } from './Shared'
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Typography } from '@mui/material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const Market = () => {
  const [historicData, setHistoricData] = useState([]);

  useEffect(() => {
    if (historicData.length < 1)
      GetHistoricData()
  })

  const GetHistoricData = () => {
    fetch('https://app.gctc.io/price/')
      .then(response => response.json()).then(data => {
        let history = data.map((row) => { return { datetime: new Date(row.datetime), price: row.price } }).sort((a, b) => {
          return (a.datetime) - (b.datetime);
        })
        console.log(history)
        //history = history.slice(data.length - 15, data.length)
        console.log(history)
        history = history.map((row) => {
          let date = row.datetime
          return {
            datetime: date.getFullYear().toString().substring(2) + '/' + (date.getMonth() + 1) + '/' + date.getDate(),
            price: row.price
          }
        })
        console.log(history)
        setHistoricData(history)
      }).catch(err => {
        console.log({ 'error returned => ': err });
      })
  }

  return (
    <CCBox>
      <CCSubBox title="GCX Historical Price">
        <Box>
          <Typography>
            <span>GCX latest price: </span>
            <span>{historicData.length > 0 && historicData[historicData.length - 1].price}</span>
          </Typography>
        </Box>
        <Box className='Chart' sx={{ width: '100%', height: '100%', p: 1 }} >
          <Line
            data={{
              labels: historicData.map((row) => row.datetime),
              datasets: [
                {
                  label: 'GCX Price',
                  data: historicData.map((row) => row.price),
                  borderColor: 'rgb(53, 162, 235)',
                  backgroundColor: 'rgba(53, 162, 235, 0.5)',
                  pointRadius: 0,
                  pointHoverRadius: 6,
                  borderWidth: 1
                }
              ]
            }}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                //title: {
                //  display: true,
                //  text: 'historicData[0]',
                //},
              },
              scales: {
                y: {
                  title: { display: true, text: 'GCX Price' }
                },
                x: {
                  adapters: {
                    type: 'time',
                    distribution: 'linear',
                    time: {
                      parser: 'yyyy-MM-dd',
                      unit: 'month'
                    },
                    title: {
                      display: true,
                      text: 'Date'
                    }
                  }
                }
              },
            }}
          />
        </Box>
      </CCSubBox>
    </CCBox>
  )
}