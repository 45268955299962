import React, { useEffect, useState, useContext } from 'react';
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Tooltip,
} from '@mui/material';

import { styled } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import CircleIcon from '@mui/icons-material/Circle';

import {
  NavLink
} from "react-router-dom";

import {
  connectWallet,
  importGcxToken,
} from '../functions/functions'

import { UserContext } from '../App';
import { toShortAddr, getChainName } from '../functions/functions';

const StyledOutlinedButton = styled(Button)((theme) => ({
  borderRadius: "16px",
  backgroundColor: "#0D920A",
  border: "1px solid #0D4F27",
  color: "white",

  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '20px',
  textTransform: 'none',
}));

export const Navbar = () => {
  const { state, dispatch } = useContext(UserContext);

  //const [walletAddress, setWalletAddress] = useState(null);
  const [showAddress, setShowAddress] = useState(false);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", async () => {
        const { chainId, tokenBalance, gcxBalance } = await connectWallet();
        importGcxToken()
        dispatch({ type: "chainId", chainId });
        dispatch({ type: "setGcxBalance", amount: gcxBalance});
        dispatch({ type: "setTokenBalance", amount: tokenBalance});
        return
      });
      window.ethereum.on("accountsChanged", async () => {
        const { address, chainId, tokenBalance, gcxBalance } = await connectWallet();
        dispatch({ type: "connectWallet", address });
        importGcxToken()
        dispatch({ type: "chainId", chainId });
        dispatch({ type: "setGcxBalance", amount: gcxBalance});
        dispatch({ type: "setTokenBalance", amount: tokenBalance});
        return
      });
    }
  })

  const handleConnectWallet = async () => {
    const { address, chainId, tokenBalance, gcxBalance } = await connectWallet()
    importGcxToken()

    //console.log(address)
    dispatch({ type: "connectWallet", address });
    dispatch({ type: "chainId", chainId });
    dispatch({ type: "setGcxBalance", amount: gcxBalance});
    dispatch({ type: "setTokenBalance", amount: tokenBalance});
    //setWalletAddress(address)
  };

  const handleClickConnected = () => {
    setShowAddress(!showAddress);
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed"
        sx={{
          boxShadow: 'none',
          backgroundColor: 'transparent',
        }}
      >
        <Toolbar
          sx={{
            backgroundColor: "white",
            justifyContent: "space-between",
            padding: "12px 11px",
            // backdropFilter: "blur(4px)",
            // boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
            zIndex: 2,
          }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ ml: "0", mr: "2", width: "166px", height: "42px", padding: 0 }}
          >
            <img src="/assets/navbar-icon.png" alt="logo" style={{ width: "100%" }} />
          </IconButton>
          {!state.walletAddress &&
            <StyledOutlinedButton color="inherit" variant="outlined" onClick={handleConnectWallet}>Connect Wallet</StyledOutlinedButton>}
          {state.walletAddress &&
            <Tooltip title={`${toShortAddr(state.walletAddress)} on ${getChainName(state.chainId)}`} open={showAddress}>
              <Button color="inherit" variant="outlined" startIcon={<CircleIcon />}
                sx={{
                  borderRaidus: "16px",
                  borderColor: '#AAA',
                  color: '#AAA',
                  ".MuiButton-startIcon": { color: "green" }
                }}
                onClick={handleClickConnected}
              >Connected</Button>
            </Tooltip>}
        </Toolbar>
        <Toolbar
          sx={{
            backgroundColor: "transparent",
            // boxShadow: "inset 0px 4px 4px -3px rgba(0, 0, 0, 0.25)",
            minHeight: 'initial',
            padding: '14px 14px',
            zIndex: 1,
            justifyContent: {
              lg: "center",
            },

            "a": {
              fontFamily: "'Avenir-Light', 'Inter', sans-serif",
              color: 'black',
              padding: "0px 8px",
              fontSize: "16px",
              lineHeight: "19px",
              fontWeight: 400,

              "&:linked": {
                color: 'black',
              },
              "&.active": {
                color: '#309D5C',
                textDecoration: 'underline',
                fontWeight: 700,
              }
            },
          }}
        >
          <a href="https://main.gctc.io/">About</a>
          <NavLink to="/" exact>Market</NavLink>
          <NavLink to="/exchange" exact>Exchange</NavLink>
          <NavLink to="/redeem" exact>Redeem</NavLink>
          <NavLink to="/list" exact>Issued Cert.</NavLink>
        </Toolbar>

      </AppBar>
    </Box>
  )
}