import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  CircularProgress,
  Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';

import { useState, useContext } from 'react';

import {
  CCBox,
  CCSubBox,
  CCSubmitButton,
} from './Shared'

import {
  redeem,
  GCX_TO_CO2_RATIO,
} from '../functions/functions'

import { UserContext } from '../App';

const StyledFormControl = styled(FormControl)((theme) => ({
  marginBottom: "10px",
  marginTop: "24px",
}));

const labelTop = -16;

const StyledInputLabel = styled(InputLabel)((theme) => ({
  left: "-15px",
  top: `${labelTop}px`,
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: '30px',
  color: 'black',
  "&.Mui-focused": {
    color: "black",
  }
}));

const Balance = styled(Typography)((theme) => ({
  position: "absolute",
  right: "0px",
  top: `${labelTop - 4}px`,
}))

const StyledOutlinedInput = styled(OutlinedInput)((theme) => ({
  borderRadius: "16px",
  backgroundColor: "white",
  height: "40px",

  "&.Mui-disabled": {
    backgroundColor: "rgba(206, 210, 217, 0.7)",
  },

  "&.MuiOutlinedInput-root": {
    boxShadow: "inset 0px 7px 3px -3px rgb(74 74 104 / 10%)",
  },

  ".MuiOutlinedInput-input": {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '13px',
    lineHeight: '16px',
  },
}));

const StyledFormHelperText = styled(FormHelperText)((theme) => ({
  ".code": {
    fontFamily: "Courier New",
    backgroundColor: 'lightgray',
    padding: '2px 4px',
    borderRadius: '4px',
  },
}));

const FULL_NAME_TOO_LONG_MESSAGE = "The name is too long.";
const FULL_NAME_CONTAINS_INVALID_CHARS_MESSAGE = "The name must contain only lowercase/uppercase A to Z, numbers from 0 to 9, white spaces, and the following symbols <span class='code'>,.'()&</span>.";
const FULL_NAME_IS_EMPTY_MESSAGE = "The name is empty or it contains only whitespaces."

function containInvalidChars(s) {
  const allowedSymbols = ",.'()&";
  const re = RegExp(`^[a-zA-Z0-9 ${allowedSymbols}]+$`);
  return !re.test(s);
}

function isEmpty(s) {
  return s.trim().length === 0;
}

export const Redeem = (props) => {
  const { state } = useContext(UserContext);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [email2, setEmail2] = useState("");
  const [gcxAmount, setGcxAmount] = useState("");
  const [fullNameFieldMessage, setFullNameFieldMessage] = useState("");

  const [showLoading, setShowLoading] = useState(false);
  const [redeemInProgress, setRedeemInProgerss] = useState(false);
  const [dialog, setDialog] = useState({
    title: "Redeem",
    message: "",
    actions: ["close"],
  });

  const handleSubmit = () => {
    setShowLoading(true);
    if (!state.walletAddress) {
      return setDialog({
        title: "Error in the form",
        message: "Connect to the wallet first.",
        actions: ["close"]
      });
    }

    if (!email) {
      return setDialog({
        title: "Error in the form",
        message: "Please provide your eamil",
        actions: ["close"]
      });
    }

    if (!email2) {
      return setDialog({
        title: "Error in the form",
        message: "Please re-enter your email.",
        actions: ["close"],
      })
    }

    if (!fullName) {
      return setDialog({
        title: "Error in the form",
        message: "Please input your full name",
        actions: ["close"]
      });
    }

    if (fullNameFieldMessage) {
      return setDialog({
        title: "Error in the form",
        message: "Your full name does not seem right. Please fix it before resubmit.",
        actions: ["close"]
      });
    }

    const gcxAmountTrimmed = gcxAmount.trim();

    if (!gcxAmountTrimmed) {
      return setDialog({
        title: "Error in the form",
        message: "Please input the amount of GCX",
        actions: ["close"]
      });
    }

    if (/^\d+$/.test(gcxAmountTrimmed) === false || parseInt(gcxAmountTrimmed) <= 0) {
      return setDialog({
        title: "Error in the form",
        message: "The amount of GCX does not seem right. Please make sure it is a whole number.",
        actions: ["close"]
      });
    }

    if (email !== email2) {
      return setDialog({
        title: "Error in the form",
        message: "Please make sure the emails you inputted are the same.",
        actions: ["close"]
      })
    }

    setDialog({
      title: "Redeem in progress",
      message: <CircularProgress />,
      actions: [],
    })
    setRedeemInProgerss(true);

    console.log("submit redeem form");
    redeem({
      walletAddress: state.walletAddress,
      fullName: fullName.trim(),
      email,
      gcxAmount: gcxAmountTrimmed,
    }).then(() => {
      console.log("redeem succeeded!");
      setDialog({
        title: "Redeem succeeded!",
        message: "You will receive an email with your certificate soon.",
        actions: ["close"],
      })
    }).catch((err) => {
      console.error("redeem failed", err);
      setDialog({
        title: "Redeem failed!",
        message: `The error is "${err.message}".`,
        actions: ["close"],
      })
    }).finally(() => {
      setRedeemInProgerss(false);
    })
  }

  const handleClose = () => {
    if (redeemInProgress){
      console.log("redeem in progress. wont close");
      return;
    }

    setShowLoading(false);
  }

  const handleFullName = (e) => {
    setFullNameFieldMessage("");

    const fullName = e.target.value;
    setFullName(fullName);
    const errorMessages = [];

    if (isEmpty(fullName)) {
      errorMessages.push(FULL_NAME_IS_EMPTY_MESSAGE);
    } else {
      if (fullName.length > 32) {
        errorMessages.push(FULL_NAME_TOO_LONG_MESSAGE);
      }

      if (containInvalidChars(fullName)) {
        errorMessages.push(FULL_NAME_CONTAINS_INVALID_CHARS_MESSAGE);
      }
    }

    setFullNameFieldMessage(errorMessages.join("<br />"));
  };

  const description = `Please input your full name and the amount of GCX you would like to redeem for Green Carbon Certificate. 1 GCX is equivalent to ${GCX_TO_CO2_RATIO} metric tonnes of CO2E [ or ${GCX_TO_CO2_RATIO} Volunteer Carbon Credit Allowance (VCCA) ]`;

  return (
    <CCBox>
      <CCSubBox title="Redeem" description={description}>
        <StyledFormControl fullWidth error={!!fullNameFieldMessage}>
          <StyledInputLabel shrink >Full Name</StyledInputLabel>
          <StyledOutlinedInput fullWidth value={fullName} onChange={handleFullName}/>
          <StyledFormHelperText dangerouslySetInnerHTML={{ __html: fullNameFieldMessage}}></StyledFormHelperText>
        </StyledFormControl>

        <StyledFormControl fullWidth>
          <StyledInputLabel shrink>Email</StyledInputLabel>
          <StyledOutlinedInput fullWidth value={email} onChange={e => setEmail(e.target.value.trim())} type="email"/>
        </StyledFormControl>

        <StyledFormControl fullWidth>
          <StyledInputLabel shrink>Re-enter Email</StyledInputLabel>
          <StyledOutlinedInput fullWidth value={email2} onChange={e => setEmail2(e.target.value.trim())} type="email"/>
        </StyledFormControl>

        <StyledFormControl fullWidth sx={{mb: "20px"}}>
          <StyledInputLabel shrink >Amount of GCX</StyledInputLabel>
          <StyledOutlinedInput fullWidth value={gcxAmount} onChange={e => setGcxAmount(e.target.value.trim())} type="numer" min="0" step="1"/>
          <Balance component="span" variant="body2">Balance: {state.gcxBalance}</Balance>
        </StyledFormControl>

        <CCSubmitButton color='primary' fullWidth onClick={handleSubmit}>Submit</CCSubmitButton>
      </CCSubBox>
      <Dialog open={showLoading} onClose={handleClose}>
        <DialogTitle>{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialog.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialog.actions.find(a => a === "close") && <Button onClick={handleClose}>Close</Button> }
        </DialogActions>
      </Dialog>
    </CCBox>
  )
}